import { GetSmsCodeRequestParams, GetSmsCodeRequestProxyParams } from '@/interface/sms';
import axios, { nextApiRequest } from '@/utils/request';

// 获取短信验证码
export async function getSmsCode(data: GetSmsCodeRequestParams) {
  return axios.request<Record<string, never>>({
    url: '/v2/sms',
    method: 'post',
    data,
  });
}

export async function getNewSmsCode(data: GetSmsCodeRequestProxyParams) {
  return nextApiRequest.request<Record<string, never>>({
    url: '/proxy/sendMsg',
    method: 'post',
    data,
  });
}
