import { useInterval } from 'ahooks';
import classNames from 'classnames';
import React, { useCallback, useState, useImperativeHandle, forwardRef } from 'react';
import styles from './index.module.scss';

interface ICountDownProps {
  time?: number;
  interval?: number;
  onSend?: () => any;
  canSend: boolean;
  startCountChange?: (v: boolean) => void;
  theme?: 'ghost' | 'block';
  mode?: 'normal' | 'captcha';
}

const CountDown: React.ForwardRefRenderFunction<{ triggerStartCount: () => void }, ICountDownProps> = (
  { time = 60000, interval = 1000, onSend, canSend, theme = 'ghost', mode = 'normal', startCountChange = () => {} },
  ref
) => {
  const [timeDown, setTimeDown] = useState(time / 1000);
  const [startCount, setStartCount] = useState(false);
  const [hasSend, setHasSend] = useState(false);

  useInterval(
    () => {
      if (timeDown - 1 <= 0) {
        setStartCount(false);
        startCountChange(false);
      } else {
        setTimeDown(timeDown - 1);
      }
    },
    interval,
    { immediate: false }
  );
  const clickHandle = useCallback(async () => {
    if (!canSend || startCount) return;
    if (mode === 'normal') {
      setHasSend(true);
      setStartCount(true);
      startCountChange(true);
      setTimeDown(time / 1000);
      onSend();
    }
  }, [canSend, mode, onSend, startCount, startCountChange, time]);

  const triggerStartCount = useCallback(() => {
    setHasSend(true);
    setStartCount(true);
    startCountChange(true);
    setTimeDown(time / 1000);
  }, [startCountChange, time]);

  useImperativeHandle(
    ref,
    () => ({
      triggerStartCount,
    }),
    [triggerStartCount]
  );

  return (
    <div className={theme === 'ghost' ? styles.ghost : styles.block}>
      <div
        className={
          !canSend
            ? classNames(styles.countdown, styles.disabled)
            : startCount
            ? classNames(styles.countdown, styles.sending)
            : styles.countdown
        }
        onClick={clickHandle}
      >
        {startCount ? `${timeDown}秒后重新发送` : hasSend ? '重新获取' : '获取验证码'}
      </div>
    </div>
  );
};

export default forwardRef(CountDown);
