import { useRequest, useSetState } from 'ahooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Checkbox from 'rc-checkbox';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useDispatch } from 'react-redux';
import UserInfoFormModalContent from '@/business-components/user-info-form-modal-content';
import Button from '@/components/button';
import CountDown from '@/components/count-down';
import Input from '@/components/input';
import { USER_TOKEN } from '@/constants/global';
import { isMobilePhone } from '@/constants/regexp';
import { signup } from '@/services/user';
import ErrorContent from '../error-content';
import styles from './index.module.scss';
interface IRegisterState {
  mobile: string;
  vcode: string;
  password: string;
}

export default function Register({ sendCode, loginCallback }) {
  const dispatch = useDispatch();
  const router = useRouter();
  const [startCount, setStartCount] = useState(false);

  const countDownRef = useRef(null);
  const { run, error, loading } = useRequest(
    // const { data, loading, loadingMore, reload, loadMore, noMore } = useRequest(
    data => {
      return signup(data);
    },
    {
      onSuccess(res) {
        dispatch({ type: 'user/setToken', payload: res.data.token });
        dispatch({ type: 'user/setUserInfo', payload: res.data });
        localStorage.setItem(USER_TOKEN, res.data.token);
        dispatch({ type: 'modal/closeUserLoginModal' });
        !router.pathname.match('cbndatabox-new') &&
          dispatch({ type: 'modal/open', payload: { content: <UserInfoFormModalContent /> } });
        loginCallback && loginCallback();
        // location.reload();
      },
      manual: true,
    }
  );
  const [registerState, setForgetState] = useSetState<IRegisterState>({
    mobile: '',
    vcode: '',
    password: '',
  });
  const [errorText, setErrorText] = useState('');
  const [canClick, setCanClick] = useState(false);
  const [protocolChecked, setProtocolChecked] = useState(true);
  useEffect(() => {
    if (
      registerState.password.length > 0 &&
      (registerState.password.length < 6 || registerState.password.length > 20)
    ) {
      setErrorText('密码长度在6~20位之间');
    } else {
      setErrorText('');
    }
  }, [registerState.password]);
  useEffect(() => {
    // setErrVisible(false);
    setCanClick(
      Boolean(!errorText && registerState.mobile && registerState.vcode && registerState.password && protocolChecked)
    );
  }, [errorText, registerState.mobile, registerState.password, registerState.vcode, protocolChecked, setCanClick]);

  let captcha;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInstance = instance => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    captcha = instance;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const captchaVerifyCallback = async captchaVerifyParam => {
    const error = await sendCode({
      type: 'new',
      phone: registerState.mobile,
      captchaVerifyParam: captchaVerifyParam,
    });
    if (error) {
      setErrorText(error?.msg);
      return {
        captchaResult: false,
        bizResult: false,
      };
    }
    return {
      captchaResult: true,
      bizResult: true,
    };
  };

  // 验证通过后调用
  const onBizResultCallback = () => {
    countDownRef.current.triggerStartCount();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.initAliyunCaptcha({
      SceneId: '6hyb3esp', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: '6eezsi', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
    return () => {
      // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
      document.getElementById('aliyunCaptcha-mask')?.remove();
      document.getElementById('aliyunCaptcha-window-popup')?.remove();
    };
  }, [captchaVerifyCallback, getInstance]);

  const registerdHandle = useCallback(() => {
    if (!canClick) return;
    run({ user: { password: registerState.password, phone: registerState.mobile, phone_code: registerState.vcode } });
  }, [canClick, registerState.mobile, registerState.password, registerState.vcode, run]);
  return (
    <div className={styles.register}>
      <Input
        className={styles.input}
        value={registerState.mobile}
        onChange={value => setForgetState({ mobile: value })}
        placeholder="请输入手机号"
      />
      <div className="captcha-b">
        <div id="captcha-element"></div>
      </div>
      <Input
        className={styles.input}
        value={registerState.vcode}
        onChange={value => setForgetState({ vcode: value })}
        placeholder="请输入验证码"
        rightSideSlot={
          <div style={{ position: 'relative' }}>
            <CountDown
              startCountChange={v => setStartCount(v)}
              ref={countDownRef}
              canSend={registerState.mobile && isMobilePhone.test(registerState.mobile)}
              mode="captcha"
            />
            <div id="captcha-button">
              {registerState.mobile && isMobilePhone.test(registerState.mobile) && !startCount && (
                <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}></div>
              )}
            </div>
          </div>
        }
      />
      <Input
        className={styles.input}
        value={registerState.password}
        onChange={value => setForgetState({ password: value })}
        placeholder="6位以上密码"
      />
      <ErrorContent errText={errorText || error?.message} style={{ marginTop: '8px' }} />
      <Button type={canClick ? 'normal' : 'default'} style={{ margin: '24px 0 0' }} onClick={registerdHandle}>
        {loading ? (
          <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
        ) : (
          '注册'
        )}
      </Button>
      <div className={classNames('flex-row-nowrap', 'flex-center-center', styles.userProtocol)}>
        <Checkbox
          checked={protocolChecked}
          onChange={(e: any) => {
            setProtocolChecked(e.target.checked);
          }}
        />
        <div className={styles.protocolText}>我已阅读并同意</div>
        <a href="/register_agreement" target="_blank" className={styles.protocolLink}>
          注册协议
        </a>
        &nbsp;
        <a href="/privacy" target="_blank" className={styles.protocolLink}>
          隐私协议
        </a>
      </div>
    </div>
  );
}
