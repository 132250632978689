import { useRequest, useSetState } from 'ahooks';
import { Modal } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import Button from '@/components/button';
import CountDown from '@/components/count-down';
import Divider from '@/components/divider';
import Input from '@/components/input';
import Tabs from '@/components/tabs';
import { USER_TOKEN } from '@/constants/global';
import { isMobilePhone } from '@/constants/regexp';
import { RootState } from '@/models/index';
import { getNewSmsCode } from '@/services/sms';
import { login, loginByNode } from '@/services/user';
import { initStore } from '@/store';
import { get, loginInwechat } from '@/utils/helpers';
import ErrorContent from './error-content';
import Register from './register';
import ResetPassword from './reset-password';
import styles from './index.module.scss';
interface LogoProps {
  loginCallback?: () => any;
}

interface ILoginState {
  mobile: string;
  vcode: string;
  account: string;
  password: string;
}

interface ICanClickState {
  mobileLogin: boolean;
  accountLogin: boolean;
}

interface IMobileLoginParams {
  code: string;
  login_type: 'phone_code';
  phone: string;
}
interface IAccountLoginParams {
  login: string;
  login_type: 'password';
  password: string;
}
type TLoginStatus = 'login' | 'resetPassword' | 'register';

export default function UserLogin({ loginCallback }: LogoProps) {
  const { dispatch } = initStore();
  const { userLoginVisible, loginType } = useSelector<RootState, RootState['modal']>(state => state.modal);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [loading, setLoading] = useSetState<any>({
    mobileLoading: false,
    accountLoading: false,
  });
  const [loginState, setLoginState] = useSetState<ILoginState>({
    mobile: '',
    vcode: '',
    account: '',
    password: '',
  });
  const [params, setParams] = useState(null);
  const [loginStatus, setLoginStatus] = useState<TLoginStatus>(loginType);
  const [startCount, setStartCount] = useState(false);

  const countDownRef = useRef(null);

  const onMobileChange = useCallback(
    value => {
      setLoginState({ mobile: value });
    },
    [setLoginState]
  );
  const onVcodeChange = useCallback(
    value => {
      setLoginState({ vcode: value });
    },
    [setLoginState]
  );
  const onAccountChange = useCallback(
    value => {
      setLoginState({ account: value });
    },
    [setLoginState]
  );
  const onPasswordChange = useCallback(
    value => {
      setLoginState({ password: value });
    },
    [setLoginState]
  );

  let captcha;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getInstance = instance => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    captcha = instance;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const captchaVerifyCallback = async captchaVerifyParam => {
    const result: any = await getNewSmsCode({
      type: 'none',
      phone: loginState.mobile,
      captchaVerifyParam: captchaVerifyParam,
    });
    const { code } = result;
    const isSuccess = code === 200;

    return {
      captchaResult: isSuccess,
      bizResult: isSuccess,
    };
  };

  // 验证通过后调用
  const onBizResultCallback = () => {
    countDownRef.current.triggerStartCount();
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.initAliyunCaptcha({
      SceneId: '6hyb3esp', // 场景ID。根据步骤二新建验证场景后，您可以在验证码场景列表，获取该场景的场景ID
      prefix: '6eezsi', // 身份标。开通阿里云验证码2.0后，您可以在控制台概览页面的实例基本信息卡片区域，获取身份标
      mode: 'popup', // 验证码模式。popup表示要集成的验证码模式为弹出式。无需修改
      element: '#captcha-element', // 页面上预留的渲染验证码的元素，与原代码中预留的页面元素保持一致。
      button: '#captcha-button', // 触发验证码弹窗的元素。button表示单击登录按钮后，触发captchaVerifyCallback函数。您可以根据实际使用的元素修改element的值
      captchaVerifyCallback: captchaVerifyCallback, // 业务请求(带验证码校验)回调函数，无需修改
      onBizResultCallback: onBizResultCallback, // 业务请求结果回调函数，无需修改
      getInstance: getInstance, // 绑定验证码实例函数，无需修改
      slideStyle: {
        width: 360,
        height: 40,
      }, // 滑块验证码样式，支持自定义宽度和高度，单位为px。其中，width最小值为320 px
      language: 'cn', // 验证码语言类型，支持简体中文（cn）、繁体中文（tw）、英文（en）
    });
    return () => {
      // 必须删除相关元素，否则再次mount多次调用 initAliyunCaptcha 会导致多次回调 captchaVerifyCallback
      document.getElementById('aliyunCaptcha-mask')?.remove();
      document.getElementById('aliyunCaptcha-window-popup')?.remove();
    };
  }, [captchaVerifyCallback, getInstance]);

  const sendCodeHandle = useCallback(async data => {
    try {
      const r = await getNewSmsCode(data);
      if (get(r, 'code') !== 200) throw r;
    } catch (error) {
      return error;
    }
  }, []);
  const [error, setError] = useState(null);
  const { run: loginRun } = useRequest(
    (data: IMobileLoginParams | IAccountLoginParams) => {
      setParams(data);
      return login(data);
    },
    {
      onSuccess: async res => {
        await loginByNode(params);
        dispatch({ type: 'user/setToken', payload: res.data.token });
        dispatch({ type: 'user/setUserInfo', payload: res.data });
        localStorage.setItem(USER_TOKEN, res.data.token);
        dispatch({ type: 'modal/closeUserLoginModal' });
        loginCallback && loginCallback();
      },
      onError: err => {
        setError(err);
        setLoading({ accountLoading: false, mobileLoading: false });
      },
      manual: true,
    }
  );
  const [canClick, setCanClick] = useSetState<ICanClickState>({
    mobileLogin: false,
    accountLogin: false,
  });

  useEffect(() => {
    setCanClick({ mobileLogin: Boolean(loginState.mobile && loginState.vcode) });
  }, [loginState.mobile, loginState.vcode, setCanClick]);
  useEffect(() => {
    // setErrVisible(false);
    setCanClick({ accountLogin: Boolean(loginState.account && loginState.password) });
  }, [loginState.account, loginState.password, setCanClick]);

  const mobileLogin = useCallback(() => {
    if (!canClick.mobileLogin || loading.mobileLoading) return;
    setLoading({ mobileLoading: true });
    loginRun({ code: loginState.vcode, login_type: 'phone_code', phone: loginState.mobile });
  }, [canClick.mobileLogin, loading.mobileLoading, loginRun, loginState.mobile, loginState.vcode, setLoading]);

  const accountLogin = useCallback(() => {
    if (!canClick.accountLogin || loading.accountLoading) return;
    setLoading({ accountLoading: true });
    loginRun({ login: loginState.account, login_type: 'password', password: loginState.password });
  }, [canClick.accountLogin, loading.accountLoading, loginRun, loginState.account, loginState.password, setLoading]);

  const handleStarCountChange = v => {
    setStartCount(v);
  };

  const OtherChoice = () => {
    return (
      <div className={styles.otherChoice}>
        <div>
          还没有账号？去{' '}
          <a style={{ color: '#f75c2f' }} onClick={() => setLoginStatus('register')}>
            注册
          </a>
        </div>
        <div className={styles.moreChoose}>
          <Divider text="您还可以选择" style={{ margin: '4px 0 12px' }} backgroundColor="#fff" />
        </div>
        <a href="javascript:void(0)" title="wechat_login" className={styles.wexinlogin} onClick={loginInwechat}>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-login_wechat"></use>
          </svg>
        </a>
      </div>
    );
  };
  const tabSet = {
    手机验证登录: (
      <>
        <Input
          className={styles.input}
          value={loginState.mobile}
          onChange={onMobileChange}
          placeholder="请输入手机号"
        />
        <div className="captcha-b">
          <div id="captcha-element"></div>
        </div>
        <Input
          className={styles.input}
          value={loginState.vcode}
          onChange={onVcodeChange}
          placeholder="请输入验证码"
          rightSideSlot={
            <div style={{ position: 'relative' }}>
              <CountDown
                startCountChange={handleStarCountChange}
                ref={countDownRef}
                canSend={loginState.mobile && isMobilePhone.test(loginState.mobile)}
                mode="captcha"
              />
              <div id="captcha-button">
                {loginState.mobile && isMobilePhone.test(loginState.mobile) && !startCount && (
                  <div style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0 }}></div>
                )}
              </div>
            </div>
          }
        />
        <ErrorContent errText={error?.message} style={{ marginTop: '8px' }} />
        <Button type={canClick.mobileLogin ? 'normal' : 'default'} style={{ marginTop: '24px' }} onClick={mobileLogin}>
          {loading.mobileLoading ? (
            <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
          ) : (
            '登录'
          )}
        </Button>
        <a className={styles.forgetPassword} onClick={() => setLoginStatus('resetPassword')}>
          忘记密码
        </a>
        <OtherChoice />
      </>
    ),
    账号密码登录: (
      <>
        <Input
          className={styles.input}
          value={loginState.account}
          onChange={onAccountChange}
          placeholder="请输入手机号或账号"
        />
        <Input
          type="password"
          className={styles.input}
          value={loginState.password}
          onChange={onPasswordChange}
          placeholder="请输入密码"
        />
        <ErrorContent errText={error?.message} style={{ marginTop: '8px' }} />
        <Button
          type={canClick.accountLogin ? 'normal' : 'default'}
          style={{ marginTop: '24px' }}
          onClick={accountLogin}
        >
          {loading.accountLoading ? (
            <ReactLoading type="spinningBubbles" width="20px" height="20px" className={styles.loadingImg} />
          ) : (
            '登录'
          )}
        </Button>
        <a className={styles.forgetPassword} onClick={() => setLoginStatus('resetPassword')}>
          忘记密码
        </a>
        <OtherChoice />
      </>
    ),
  };

  const handleOk = () => {
    // setModalText('The modal will be closed after two seconds');
    setConfirmLoading(true);
    setTimeout(() => {
      dispatch({ type: 'modal/closeUserLoginModal' });
      setConfirmLoading(false);
    }, 2000);
  };

  const handleCancel = () => {
    dispatch({ type: 'modal/closeUserLoginModal' });
  };
  useEffect(() => {
    setLoginStatus(loginType);
  }, [loginType, userLoginVisible]);
  return (
    <div>
      <Modal
        visible={userLoginVisible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <form
          name="user-login"
          className={classNames('flex-column-nowrap', 'flex-center-center', styles.userLoginContent)}
        >
          <img
            className={styles.userLoginIcon}
            src="//assets-oss.cbndata.com/cbndata-refactor-fe/FnuIjHWHuYVLWHJP7c59PDq3YUiO.png"
          />
          {loginStatus === 'login' ? (
            <Tabs
              tabData={tabSet}
              onSelectChanged={() => {
                setError(null);
              }}
            />
          ) : loginStatus === 'resetPassword' ? (
            <ResetPassword sendCode={sendCodeHandle} loginCallback={loginCallback} />
          ) : (
            <Register sendCode={sendCodeHandle} loginCallback={loginCallback} />
          )}
        </form>
      </Modal>
    </div>
  );
}
